import cn from 'classnames';
import * as React from 'react';

import Icon from '@/components/Icon';

import InputField, { InputFieldProps } from '../InputField/InputField';

import s from './SelectInputField.module.scss';

type SelectInputFieldProps = Omit<InputFieldProps, 'after' | 'filled' | 'size'> & {
  size?: 'medium' | 'large';
  isError?: boolean;
};

const SelectInputField = React.forwardRef<HTMLInputElement, SelectInputFieldProps>(
  ({ className, size = 'medium', ...props }, ref: React.ForwardedRef<HTMLInputElement>) => {
    return (
      <InputField
        {...props}
        ref={ref}
        className={cn(s.input, s[`input-${size}`], className)}
        filled={Boolean(props.value)}
        after={
          <Icon
            className={cn(s.input_arrow, s[`input_arrow-${size}`])}
            color="black"
            iconName="IconArrowDropDown"
            size={24}
            iconRotation={props.focused ? 180 : 0}
          />
        }
      />
    );
  },
);

export default SelectInputField;
