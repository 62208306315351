import { action, computed, makeObservable } from 'mobx';

import { AgencyEditingResponseData, AgencyServer, IAgency } from '@/entities/agency';
import { BankDetailsModel } from '@/models/agency/bankDetails';
import { ValueModel } from '@/models/ValueModel';

import { PhoneNumber } from '../PhoneNumber';

export class AgencyModel implements IAgency {
  readonly id;
  readonly legalName;
  readonly isForma;
  readonly marketingName;
  readonly inn;
  readonly bookResponsibility;
  readonly isIP;
  readonly bankDetailsModel: ValueModel<IAgency['bankDetails']>;

  readonly kppModel: ValueModel<IAgency['kpp']>;
  readonly ogrnModel: ValueModel<IAgency['ogrn']>;
  readonly addressModel: ValueModel<IAgency['address']>;
  readonly usnModel: ValueModel<IAgency['usn']>;
  readonly taxModel: ValueModel<IAgency['tax']>;
  readonly legalEntityModel: ValueModel<IAgency['legalEntity']>;
  readonly authorizedPersonModel: ValueModel<IAgency['authorizedPerson']>;
  readonly legalBasisModel: ValueModel<IAgency['legalBasis']>;

  constructor({
    id,
    legalName,
    isForma,
    marketingName,
    inn,
    kpp,
    ogrn,
    address,
    bookResponsibility,
    bankDetails,
    isIP,
    usn,
    tax,
    legalEntity,
    authorizedPerson,
    legalBasis,
  }: IAgency) {
    this.id = id;
    this.legalName = legalName;
    this.isForma = isForma;
    this.marketingName = marketingName;
    this.inn = inn;
    this.kppModel = new ValueModel<IAgency['kpp']>(kpp);
    this.ogrnModel = new ValueModel<IAgency['ogrn']>(ogrn);
    this.addressModel = new ValueModel<IAgency['address']>(address);
    this.bookResponsibility = bookResponsibility;
    this.bankDetailsModel = new ValueModel<IAgency['bankDetails']>(bankDetails);
    this.isIP = isIP;
    this.usnModel = new ValueModel<IAgency['usn']>(usn);
    this.taxModel = new ValueModel<IAgency['tax']>(tax);
    this.legalEntityModel = new ValueModel<IAgency['legalEntity']>(legalEntity);
    this.authorizedPersonModel = new ValueModel<IAgency['authorizedPerson']>(authorizedPerson);
    this.legalBasisModel = new ValueModel<IAgency['legalBasis']>(legalBasis);

    makeObservable(this, {
      kpp: computed,
      ogrn: computed,
      address: computed,
      bankDetails: computed,

      updateCommonInfo: action,
    });
  }

  get bankDetails(): IAgency['bankDetails'] {
    return this.bankDetailsModel.value;
  }

  get kpp(): IAgency['kpp'] {
    return this.kppModel.value;
  }

  get ogrn(): IAgency['ogrn'] {
    return this.ogrnModel.value;
  }

  get address(): IAgency['address'] {
    return this.addressModel.value;
  }

  get usn(): IAgency['usn'] {
    return this.usnModel.value;
  }

  get tax(): IAgency['tax'] {
    return this.taxModel.value;
  }

  get legalEntity(): IAgency['legalEntity'] {
    return this.legalEntityModel.value;
  }

  get authorizedPerson(): IAgency['authorizedPerson'] {
    return this.authorizedPersonModel.value;
  }

  get legalBasis(): IAgency['legalBasis'] {
    return this.legalBasisModel.value;
  }

  updateCommonInfo(raw: AgencyEditingResponseData) {
    this.kppModel.change(raw.kpp ?? '');
    this.ogrnModel.change(raw.ogrn ?? '');
    this.addressModel.change(raw.address ?? '');
    this.usnModel.change(raw.usn ?? false);
    this.taxModel.change(raw.tax !== null ? String(raw.tax) : null);
    this.legalBasisModel.change(raw.legal_basis ?? '');
    this.legalEntityModel.change({
      email: raw.legal_entity_email ?? '',
      phone: raw.legal_entity_phone ? PhoneNumber.fromJson(raw.legal_entity_phone) : '',
    });
    this.authorizedPersonModel.change({
      firstName: raw.authorized_person_first_name ?? '',
      lastName: raw.authorized_person_last_name ?? '',
      patronymic: raw.authorized_person_patronymic ?? '',
    });
  }

  updateBankDetails(bankDetails: BankDetailsModel) {
    this.bankDetailsModel.change(bankDetails);
  }

  static fromJson(raw: AgencyServer): AgencyModel {
    const bankDetails = raw.agency_bank_details ? BankDetailsModel.fromJson(raw.agency_bank_details) : null;

    return new AgencyModel({
      id: raw.id,
      legalName: raw.legal_name,
      isForma: raw.is_forma,
      marketingName: raw.marketing_name,
      inn: raw.inn,
      kpp: raw.kpp,
      ogrn: raw.ogrn,
      address: raw.address,
      bookResponsibility: raw.book_responsibility,
      isIP: raw.is_ip,
      usn: raw.usn,
      tax: raw.tax !== null ? String(raw.tax) : null,
      legalEntity: {
        phone: raw.legal_entity_phone ? PhoneNumber.fromJson(raw.legal_entity_phone) : null,
        email: raw.legal_entity_email,
      },
      authorizedPerson: {
        firstName: raw.authorized_person_first_name,
        lastName: raw.authorized_person_last_name,
        patronymic: raw.authorized_person_patronymic,
      },
      legalBasis: raw.legal_basis,
      bankDetails,
    });
  }
}
